/**
 * 檔案管理 相關 api
 */
import req from './config/https.js';

/**
  * 定義接口
  * 1. apiUploadFilePostStore 上傳檔案-CRUD-store
  * 2. apiDownloadFileGet 下載檔案
  * 3. apiReadFileGet 讀取檔案文本
  *
  */
// 1.
export const apiUploadFilePostStoreV2 = function (params, onUploadProgress) {
  return req('post', '/api/fm/upload', { isfile: true, data: params, onUploadProgress: onUploadProgress });
};
// 1.
export const apiUploadFilePostStore = function (params) {
  return req('post', '/api/fm/upload', { isfile: true, data: params });
};
// 2.
export const apiDownloadFileGetByUrl = function (url) {
  return req('get', url, { isBlob: true });
};
// 2.
export const apiDownloadFileGetV2 = function (params, onUploadProgress) {
  const _type = params.type != null ? params.type : '';
  const _id = params.id != null ? params.id : '';
  return req('get', `/api/fm/download/${_type}/${_id}`, { isBlob: true, onUploadProgress: onUploadProgress });
};
export const apiDownloadFileGet = function (params) {
  const _type = params.type != null ? params.type : '';
  const _id = params.id != null ? params.id : '';
  return req('get', `/api/fm/download/${_type}/${_id}`, { isBlob: true });
};
// 3.
export const apiViewFileGet = function (params) {
  const _type = params.type != null ? params.type : '';
  const _id = params.id != null ? params.id : '';
  return req('get', `/api/fm/view/${_type}/${_id}`, { isBlob: true });
};

// // 1.
// export const apiOAuthPostTokens = (params) =>
//   req('post', '/api/oauth/oauth_token', params);
// // 2.
// export const apiOAuthPostRefreshTokens = (params) =>
//   req('post', '/api/oauth/refresh_token', params);
