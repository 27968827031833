var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","scrollable":""},model:{value:(_vm.viewer.active),callback:function ($$v) {_vm.$set(_vm.viewer, "active", $$v)},expression:"viewer.active"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"teal"}},[_c('v-toolbar-title',[_vm._v("預覽檔案")]),(!_vm.viewer.multiple)?[_c('v-spacer'),_c('v-toolbar-items',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","text":"","disabled":!(_vm.viewer.pageCount - _vm.viewer.currentPage == 0)},on:{"click":function($event){return _vm.clickBtns('previous')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left-bold")])],1)]}}],null,false,1064738552)},[_c('span',[_vm._v("上一頁")])]),_c('div',{staticClass:"align-self-center"},[_vm._v(" "+_vm._s("第")+" ")]),_c('v-select',{staticClass:"mx-1 align-self-center",style:(_vm.viewer.pageCount >= 100 ? 'max-width: 6rem' : 'max-width: 5rem'),attrs:{"items":_vm.viewer.pageOptions,"dense":"","outlined":"","hide-details":""},model:{value:(_vm.viewer.pageNum),callback:function ($$v) {_vm.$set(_vm.viewer, "pageNum", $$v)},expression:"viewer.pageNum"}}),_c('div',{staticClass:"align-self-center"},[_vm._v(" "+_vm._s(("頁／共 " + (_vm.viewer.pageCount) + " 頁"))+" ")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","text":"","disabled":!(_vm.viewer.pageCount - _vm.viewer.currentPage >= 1)},on:{"click":function($event){return _vm.clickBtns('next')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-right-bold")])],1)]}}],null,false,8762871)},[_c('span',[_vm._v("下一頁")])])],1)]:_vm._e(),_c('v-spacer'),_c('v-toolbar-items',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.clickBtns('save')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}])},[_c('span',[_vm._v("下載")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.clickBtns('print')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-printer")])],1)]}}])},[_c('span',[_vm._v("列印")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.clickBtns('close')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("關閉")])])],1)],2),(_vm.viewer.mode === 'view')?[(!_vm.viewer.multiple)?_c('v-card-text',[_c('pdf',{ref:"pdf",attrs:{"src":_vm.viewer.src,"page":_vm.viewer.pageNum},on:{"num-pages":function($event){_vm.viewer.pageCount = $event},"page-loaded":function($event){_vm.viewer.currentPage = $event}}})],1):_c('v-card-text',_vm._l((_vm.viewer.numPages),function(i){return _c('pdf',{key:i,ref:"pdf",refInFor:true,style:(("display: inline-block; width: " + (_vm.viewer.width))),attrs:{"src":_vm.viewer.src,"page":i}})}),1)]:_vm._e(),_c('div',{staticStyle:{"flex":"1 1 auto"}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }