<template>
  <v-dialog
    v-model="viewer.active"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
  >
    <v-card tile>
      <v-toolbar flat dark color="teal">
        <v-toolbar-title>預覽檔案</v-toolbar-title>
        <template v-if="!viewer.multiple">
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  text
                  :disabled="!(viewer.pageCount - viewer.currentPage == 0)"
                  @click="clickBtns('previous')"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-arrow-left-bold</v-icon>
                </v-btn>
              </template>
              <span>上一頁</span>
            </v-tooltip>
            <div class="align-self-center">
              {{ "第" }}
            </div>
            <v-select
              v-model="viewer.pageNum"
              :items="viewer.pageOptions"
              dense
              outlined
              hide-details
              class="mx-1 align-self-center"
              :style="
                viewer.pageCount >= 100 ? 'max-width: 6rem' : 'max-width: 5rem'
              "
            >
            </v-select>
            <div class="align-self-center">
              {{ `頁／共 ${viewer.pageCount} 頁` }}
            </div>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  text
                  :disabled="!(viewer.pageCount - viewer.currentPage >= 1)"
                  @click="clickBtns('next')"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-arrow-right-bold</v-icon>
                </v-btn>
              </template>
              <span>下一頁</span>
            </v-tooltip>
          </v-toolbar-items>
        </template>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                text
                @click="clickBtns('save')"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>下載</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                text
                @click="clickBtns('print')"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </template>
            <span>列印</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                text
                @click="clickBtns('close')"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>關閉</span>
          </v-tooltip>
        </v-toolbar-items>
      </v-toolbar>
      <template v-if="viewer.mode === 'view'">
        <v-card-text v-if="!viewer.multiple">
          <pdf
            ref="pdf"
            :src="viewer.src"
            :page="viewer.pageNum"
            @num-pages="viewer.pageCount = $event"
            @page-loaded="viewer.currentPage = $event"
          ></pdf>
        </v-card-text>
        <v-card-text v-else>
          <pdf
            ref="pdf"
            v-for="i in viewer.numPages"
            :key="i"
            :src="viewer.src"
            :page="i"
            :style="`display: inline-block; width: ${viewer.width}`"
          ></pdf>
        </v-card-text>
      </template>
      <div style="flex: 1 1 auto"></div>
    </v-card>
  </v-dialog>
</template>

<script>
import { apiViewFileGet, apiDownloadFileGet, apiDownloadFileGetByUrl } from '@api/filemgr.js';
import pdf from 'vue-pdf';

export default {
  components: { pdf },
  props: {
    initVars: {
      type: Object,
      default: function () {
        return {
        };
      }
    }
  },
  data () {
    return {
      viewer: {}
    };
  },
  watch: {
    initVars: {
      handler (newVal, oldVal) {
        // console.log('[PDFViewer][watch] ... initVars');
        this.initSet();
      },
      deep: true
    },
    'viewer.pageCount': {
      handler (newVal) {
        let _pageOpts = [];
        if (newVal > 0) {
          _pageOpts = Array.from(Array(newVal)).map((e, i) => i + 1); // Array.from(Array(newVal).keys().forEach);
        }
        this.$set(this.viewer, 'pageOptions', _pageOpts);
      }
    }
  },
  methods: {
    /**
     * [initSet] 初始化
     */
    initSet () {
      const lists = {
        active: false,
        mode: '',
        src: null,
        type: '',
        id: '',
        pageNum: 1, // 當前顯示的頁面
        pageOptions: [],
        numPages: null,
        currentPage: 0,
        pageCount: 0,
        width: '100%', // 多頁面顯示倍數
        multiple: false
      };
      Object.keys(lists).forEach(function (k) {
        this.viewer[k] = this.initVars[k] != null ? this.initVars[k] : lists[k];
      }, this);

      switch (this.viewer.mode) {
        case 'view':
          this.getViewFile();
          break;
        case 'download':
          this.getDownloadFile();
          break;
        case 'downloadUrl': // 下載檔案 by Url (laravel 須建立 storage links)
          this.getDownloadFile(true);
          break;
      }
    },
    /**
     * [runMsgDialog] control parent's MsgDialog
     */
    runMsgDialog (_dialog = null) {
      if (_dialog) {
        this.$emit('update:runMsgDialog', _dialog);
      }
    },
    /**
     * [clickBtns]
     */
    clickBtns (tg = '') {
      //   console.log(`[PDFViewer][clickBtns] ... tg: ${tg}`);
      const _viewer = this.$options.data().viewer;
      switch (tg) {
        case 'close': // 關閉預覽
          this.viewer.active = false;
          this.$set(this, 'viewer', _viewer);
          break;
        case 'save': // 儲存檔案
          this.getDownloadFile();
          this.viewer.active = false;
          this.$set(this, 'viewer', _viewer);
          break;
        case 'print': // 列印
          this.printFile();
          break;
        case 'next': // 下一頁
          this.viewer.pageNum++;
          break;
        case 'previous': // 上一頁
          this.viewer.pageNum--;
          break;
      }
    },
    /**
     * [viewFile] 檢視檔案
     */
    async getViewFile () {
      // console.log('[PDFViewer][getViewFile] ...');
      if (this.initVars.type === '' || this.initVars.id === '') {
        return false;
      }
      let result = null;
      let blob = null;
      let url = '';
      const _viewer = { ...this.viewer, ...this.initVars };
      switch (this.initVars.type) {
        case 'quot': // 檢視估價單
          result = await apiViewFileGet({ type: this.initVars.type, id: this.initVars.id });
          if (result && result.status) {
            blob = new Blob([result.data], { type: 'application/pdf' });
            url = window.URL.createObjectURL(blob);
            if (!url) {
              return false;
            }
            // single page
            // _viewer.src = src;
            // this.$set(this, 'viewer', _viewer);

            // multiple pages
            _viewer.src = pdf.createLoadingTask(url);
            _viewer.src.promise.then(function (result) {
              _viewer.numPages = result.numPages;
            });
            // console.log(_viewer);
            this.$set(this, 'viewer', _viewer);
          }
          break;
      }
    },
    /**
     * [getDownloadFile] 下載檔案
     */
    async getDownloadFile (byUrl = false) {
      if (!byUrl && (this.initVars.type === '' || this.initVars.id === '')) {
        return false;
      }
      if (byUrl && this.initVars.url === '') {
        return false;
      }
      const filename = this.initVars.filename != null && this.initVars.filename !== '' ? this.initVars.filename : 'download.pdf';
      let result = null;
      let blob = null;
      let link = null;
      let _dialog = {};
      switch (this.initVars.type) {
        case 'quot': // 檢視估價單
          if (byUrl) {
            result = await apiDownloadFileGetByUrl(this.initVars.url);
            if (window.navigator.msSaveBlob) { // IE以及IE內核的瀏覽器
              // 回傳的blob數據，如數據非blob，需要自行處理blob => new Blob([response.data])，fileName就是下载後的存檔名稱(含副檔名)
              window.navigator.msSaveBlob(result.data, filename);
            } else {
              blob = result.data;
              link = document.createElement('a');
              link.style.display = 'none';
              link.href = window.URL.createObjectURL(blob);
              link.download = filename;
              link.click();
              // link.setAttribute('download', _filename); // 下載後的檔案名稱(含副檔名)
              // document.body.appendChild(link);
              // link.click();
              // document.body.removeChild(link); // 完成後移除
              // window.URL.revokeObjectURL(_url); // 釋放 blob對象
            }
          } else {
            // dialog
            _dialog = {
              title: '檔案下載中 ...',
              persistent: true,
              process: true,
              width: '400px'
            };
            this.runMsgDialog(_dialog);

            result = await apiDownloadFileGet({ type: this.initVars.type, id: this.initVars.id });

            if (result && result.status) {
              if (window.navigator.msSaveBlob) { // IE以及IE內核的瀏覽器
                // 回傳的blob數據，如數據非blob，需要自行處理blob => new Blob([response.data])，fileName就是下载後的存檔名稱(含副檔名)
                window.navigator.msSaveBlob(new Blob[result.data](), filename);
              } else {
                blob = new Blob([result.data], { type: 'application/pdf' });
                link = document.createElement('a');
                link.style.display = 'none';
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;
                link.click();
              }
            }
            // 關閉dialog
            _dialog = { isClose: true };
            this.runMsgDialog(_dialog);
          }
          break;
      }
    },
    /**
     * [printFile] 列印檔案
     */
    async printFile () {
      //   console.log(this.$refs.pdf);
      if (this.viewer.multiple) {
        this.$refs.pdf[0].print();
      } else {
        this.$refs.pdf.print();
      }
    }
  }
};
</script>
