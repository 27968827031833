<template>
  <v-sheet :color="mVars.sheetColor" rounded-1 class="px-4 py-3">
    <v-container fluid>
      <!-- Master -->
      <v-row>
        <v-col cols="12" class="px-0 pb-0">
          <v-chip color="teal" label text-color="white" class="subtitle-1">{{
            mVars.title
          }}</v-chip>
        </v-col>
        <v-col class="px-0">
          <v-card>
            <v-card-text class="pa-0">
              <v-sheet
                :id="`scroll-threshold-master-${target}`"
                class="overflow-y-auto"
                max-height="25vh"
              >
                <v-responsive>
                  <v-data-table
                    v-model="mVars.value"
                    :headers="mVars.headers"
                    :items="mVars.items"
                    :items-per-page="mVars.itemsPerPage"
                    :loading="mVars.loading"
                    :loading-text="mVars.loadingText"
                    :no-data-text="'查無資料'"
                    dense
                    class="elevation-1 table-master"
                  >
                    <template v-slot:item="props">
                      <tr @click="queryDetail(props.index, $event)">
                        <template v-for="(hd, hid) in props.headers">
                          <td
                            :class="hd.classTd != null ? hd.classTd : ''"
                            :key="`r-${hid}`"
                            v-if="hd.visible == null || !!hd.visible"
                          >
                            {{
                              hd.value === "sno"
                                ? props.index + 1
                                : props.item[hd.value]
                            }}
                          </td>
                        </template>
                      </tr>
                    </template>
                  </v-data-table>
                </v-responsive>
              </v-sheet>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Detail -->
      <v-row>
        <v-col cols="12" class="px-0">
          <v-card>
            <v-tabs
              v-model="dVars.tab"
              background-color="teal"
              dark
              :icons-and-text="dVars.iconsAndText"
              show-arrows
              slider-size="5"
              :height="!dVars.height ? false : dVars.height"
              grow
            >
              <v-tab
                class="body-1 font-weight-bold"
                v-for="d in dVars.items"
                :key="`dtab-${d.id}`"
              >
                {{ d.label }}
              </v-tab>
            </v-tabs>
            <v-divider color="teal"></v-divider>
            <v-tabs-items v-model="dVars.tab" touchless>
              <v-tab-item
                v-for="(d, idx) in dVars.items"
                :key="`dtabitem-${d.id}`"
              >
                <v-card>
                  <v-card-text>
                    <v-sheet
                      v-slavescroll="`btn-scroll-${target}-${d.id}`"
                      :id="`scroll-threshold-slave-${target}-${d.id}`"
                      class="upload-speed-dialog overflow-y-auto"
                      max-height="35vh"
                    >
                      <!-- sheet of quot and record with table -->
                      <template v-if="['quot', 'record'].indexOf(idx) >= 0">
                        <v-responsive>
                          <v-container fluid class="ma-0 pa-0">
                            <v-row>
                              <!-- 上傳檔案 input with quot-->
                              <v-col
                                cols="12"
                                class="d-flex flex-row"
                                v-if="
                                  idx === 'quot' &&
                                  !!d.speedDial.subs.upload.isCollapse
                                "
                              >
                                <v-file-input
                                  v-model="d.speedDial.subs.upload.value"
                                  :accept="d.speedDial.subs.upload.accept"
                                  :label="d.speedDial.subs.upload.label"
                                  :rules="
                                    d.speedDial.subs.upload.enabled
                                      ? d.speedDial.subs.upload.rules
                                      : []
                                  "
                                  dense
                                  filled
                                  show-size
                                  :hide-details="
                                    d.speedDial.subs.upload.hideDetails
                                  "
                                  style="max-width: 30rem"
                                ></v-file-input>
                                <v-btn
                                  :disabled="!btns.quot.upload"
                                  color="teal"
                                  outlined
                                  class="ml-5 align-self-center"
                                  @click="execFile('upload', [idx, -1])"
                                  >送出</v-btn
                                >
                                <v-btn
                                  color="teal"
                                  outlined
                                  class="ml-5 align-self-center"
                                  @click="execFile('upload_drop', [idx, -1])"
                                  >關閉</v-btn
                                >
                              </v-col>
                              <!-- table content, ps. height and fixed-header should combined to set-->
                              <v-col cols="12">
                                <v-data-table
                                  :headers="d.headers"
                                  :items="d.data"
                                  :items-per-page="d.itemsPerPage"
                                  :loading="d.loading"
                                  :loading-text="d.loadingText"
                                  :no-data-text="d.noDataText"
                                  dense
                                  height="25vh"
                                  fixed-header
                                  class="table-detail"
                                >
                                  <template v-slot:item="props">
                                    <tr>
                                      <template
                                        v-for="(hd, hid) in props.headers"
                                      >
                                        <td
                                          :class="
                                            hd.classTd != null ? hd.classTd : ''
                                          "
                                          :key="`r-${hid}`"
                                          v-if="
                                            hd.visible == null || !!hd.visible
                                          "
                                        >
                                          <v-btn
                                            color="warning"
                                            outlined
                                            small
                                            class="btn-small"
                                            v-text="'檢視'"
                                            @click="
                                              execFile('view', [
                                                idx,
                                                props.index,
                                              ])
                                            "
                                            v-if="hd.value === 'action'"
                                          ></v-btn>
                                          <v-btn
                                            color="warning"
                                            outlined
                                            small
                                            class="btn-small"
                                            v-text="'下載'"
                                            @click="
                                              execFile('download', [
                                                idx,
                                                props.index,
                                              ])
                                            "
                                            v-if="hd.value === 'action'"
                                          ></v-btn>
                                          {{
                                            hd.value === "action"
                                              ? ""
                                              : hd.value === "sno"
                                              ? props.index + 1
                                              : hd.value === "status"
                                              ? props.item[hd.value] === "Y"
                                                ? "V"
                                                : ""
                                              : props.item[hd.value]
                                          }}
                                        </td>
                                      </template>
                                    </tr>
                                  </template>
                                </v-data-table>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-responsive>
                        <!-- 功能按鈕 -->
                        <v-speed-dial
                          v-model="d.speedDial.fab"
                          :top="d.speedDial.top"
                          :right="d.speedDial.right"
                          :left="d.speedDial.left"
                          :bottom="d.speedDial.bottom"
                          :direction="d.speedDial.direction"
                          :open-on-hover="d.speedDial.hover"
                          :transition="d.speedDial.transition"
                          :class="d.speedDial.class"
                          v-if="
                            ['quot', 'inst'].indexOf(target) >= 0 &&
                            !!d.speedDial &&
                            !!d.speedDial.subs &&
                            Object.keys(d.speedDial.subs).length > 0
                          "
                        >
                          <template v-slot:activator>
                            <v-btn
                              v-model="d.speedDial.fab"
                              :id="`btn-scroll-${target}-${d.id}`"
                              color="warning"
                              dark
                              fab
                              small
                            >
                              <v-icon v-if="d.speedDial.fab">
                                {{ d.speedDial.icon[1] }}
                              </v-icon>
                              <v-icon v-else>
                                {{ d.speedDial.icon[0] }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <template
                            v-for="(sd_item, sd_idx) in d.speedDial.subs"
                          >
                            <v-tooltip
                              left
                              v-if="!!sd_item.enabled"
                              :key="`tip-${target}-${idx}-${sd_idx}`"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  fab
                                  dark
                                  small
                                  :color="sd_item.color"
                                  @click="clickBtns(idx, sd_idx)"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon>{{
                                    sd_item.isCollapse
                                      ? sd_item.icon[1]
                                      : sd_item.icon[0]
                                  }}</v-icon>
                                </v-btn>
                              </template>
                              <span>{{
                                sd_item.isCollapse
                                  ? sd_item.tooltip[1]
                                  : sd_item.tooltip[0]
                              }}</span>
                            </v-tooltip>
                          </template>
                        </v-speed-dial>
                      </template>
                      <!-- sheet for evaluation -->
                      <v-responsive v-else-if="['inst'].indexOf(idx) >= 0">
                        <v-container fluid class="pa-0">
                          <v-row dense>
                            <template v-if="Object.keys(d.data).length <= 0">
                              <v-col cols="12">{{ d.noDataText }}</v-col>
                            </template>
                            <template v-else>
                              <template v-if="!!d.loading">
                                <v-progress-linear
                                  color="deep-purple accent-4"
                                  indeterminate
                                  rounded
                                  height="6"
                                >
                                </v-progress-linear>
                              </template>
                              <template v-else>
                                <v-col
                                  v-for="d_hd in d.headers"
                                  cols="12"
                                  sm="12"
                                  :md="d_hd.cols != null ? d_hd.cols : ''"
                                  :key="`d-${idx}-${d_hd.value}`"
                                >
                                  <v-chip
                                    label
                                    color="teal lighten-4"
                                    class="mr-2"
                                    v-text="d_hd.text"
                                  ></v-chip
                                  >{{ `${d.data[d_hd.value]}` }}
                                </v-col>
                              </template>
                              <!-- edit btn for evaluation -->
                              <v-tooltip bottom v-if="target !== 'finish'">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-fab-transition>
                                    <v-btn
                                      :id="`btn-scroll-${target}-${d.id}`"
                                      fab
                                      small
                                      absolute
                                      top
                                      right
                                      color="warning"
                                      class="error"
                                      :style="`position: absolute; top: 0px;`"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon> mdi-pencil </v-icon>
                                    </v-btn>
                                  </v-fab-transition>
                                </template>
                                <span>編輯場勘資料</span>
                              </v-tooltip>
                            </template>
                          </v-row>
                        </v-container>
                      </v-responsive>
                    </v-sheet>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- PDFViewer -->
    <PDFViewer
      :initVars="pdfVars"
      v-on:update:runMsgDialog="runMsgDialog"
    ></PDFViewer>
  </v-sheet>
</template>
<script>
/**
 * [需求單 >> 列表 >> Master/Slave]
 */
import { apiUploadFilePostStoreV2 } from '@api/filemgr.js';
import PDFViewer from '@component/print/PDFViewer';

export default {
  name: 'MasterDetail',
  components: { PDFViewer },
  props: {
    target: {
      type: String,
      default: ''
    },
    initVars: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  data () {
    return {
      // is_seq: '', // 目前檢視的需求單號
      dialog: {
        init: false,
        active: false,
        isClose: false, // 關閉訊息窗
        title: '',
        msg: '',
        process: false,
        persistent: true, // 預設要點擊按鈕
        width: '350px',
        btns: {}
      },
      btns: {
        quot: { upload: true } // 上傳估價單 - 送出
      },
      pdfVars: {
        active: false
      },
      // printVars: {
      //   pdf: {
      //     active: false,
      //     url: '',
      //     data: null
      //   }
      // },
      title: {
        wait: '未接單一覽表',
        quot: '未報價一覽表',
        inst: '裝機中一覽表',
        closed: '未結案一覽表',
        finish: '已結案一覽表'
      },
      mVars: { // 主檔
        title: '一覽表',
        value: [], // 選擇列
        rid: 0, // 點擊items的索引值(預設第一列)
        sheetColor: 'light-green lighten-5',
        itemsPerPage: 5,
        loading: false,
        loadingText: '處理中，請稍後...',
        headers: [
          { value: 'sno', text: '項次', align: 'center', sortable: false, width: '4rem', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
          { value: 'is_seq', text: '需求單號', align: 'center', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
          { value: 'buy_type', visible: false, class: 'd-none' },
          { value: 'buy_type_name', text: '車主身分', align: 'center', width: '10rem', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
          { value: 'brand', visible: false, class: 'd-none' },
          { value: 'brand_name', text: '車廠', align: 'center', width: '6rem', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
          { value: 'model', visible: false, class: 'd-none' },
          { value: 'model_name', text: '車款', align: 'center', width: '8rem', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
          { value: 'slip_type', visible: false, class: 'd-none' },
          { value: 'slip_type_name', text: '編號類型', align: 'center', width: '8rem', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
          { value: 'slip_code', text: '編號', align: 'center', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
          { value: 'status', visible: false, text: '單據狀態', class: 'd-none body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
          { value: 'status_name', text: '單據狀態', align: 'center', width: '8rem', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
          { value: 'ref_seq', text: '群組流水號', align: 'center', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' }
        ],
        items: []
      },
      dVars: { // 明細 by Tabs
        tab: null,
        rid: 'inst', // 目前所在頁籤
        iconAndText: true,
        height: '2.5rem',
        items: {
          inst: {
            id: 'inst',
            label: '場勘資訊',
            loading: false,
            loadingText: '取得場勘資訊中...',
            noDataText: '查無場勘資訊',
            headers: [
              { value: 'is_seq', text: '需求單號', cols: 4 },
              { value: 'assign_supplier', text: '車主是否指定裝機廠商', cols: 4 },
              { value: 'supplier', text: '已派單廠商', cols: 4 },
              { value: 'parking_addr', text: '場勘地址', cols: 12 },
              { value: 'parking_env', text: '住家型態', cols: 4 },
              { value: 'parking_type', text: '車位種類', cols: 8 },
              { value: 'community', text: '社區名稱', cols: 4 },
              { value: 'parking_model', text: '車位型式', cols: 4 },
              { value: 'parking_loc', text: '車位位置', cols: 4 },
              { value: 'contacter', text: '(場勘)聯絡人/電話/時間', cols: 12 },
              { value: 'cmmunity_contact', text: '(場勘)社區電話/下次管委會日期', cols: 12 },
              { value: 'opinions', text: '特別需求/意見', cols: 12 }
            ],
            data: {}
          },
          quot: {
            id: 'quot',
            label: '報價單',
            itemsPerPage: 5,
            loading: false,
            loadingText: '取得報價單資料中...',
            noDataText: '查無報價單資料',
            headers: [
              { value: 'sno', text: '項次', align: 'center', sortable: false, width: '4rem', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
              // { value: 'is_seq', visible: false, class: 'd-none' },
              // { value: 'up_seq', visible: false, class: 'd-none' },
              { value: 'ext', text: '類型', align: 'center', sortable: true, width: '5rem', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
              { value: 'filename', text: '檔案名稱', align: 'center', sortable: false, class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
              { value: 'status', text: '有效', align: 'center', sortable: true, width: '5rem', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
              { value: 'action', text: '動作', align: 'center', sortable: false, class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
              { value: 'upload_dt', text: '上傳日期', align: 'center', sortable: true, width: '12rem', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
              { value: 'mod_dt', text: '異動日期', align: 'center', sortable: true, width: '12rem', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' }
            ],
            data: [],
            speedDial: {
              direction: 'bottom',
              fab: false,
              icon: ['mdi-chevron-double-down', 'mdi-chevron-double-up'],
              fling: false,
              hover: false,
              top: true,
              right: true,
              bottom: false,
              left: false,
              transition: 'slide-y-reverse-transition',
              class: 'mt-0 mr-5', // 'mt-6',
              subs: {
                upload: {
                  id: 'upload',
                  value: null,
                  label: '上傳',
                  icon: ['mdi-cloud-upload-outline', 'mdi-cloud-off-outline'],
                  enabled: true, /* 是否啟用按鈕 */
                  rules: [
                    v => !!v || '請選擇檔案',
                    v => !v || !(v.size > 2_097_152) || '上傳檔案不可超過 2 MB' // 2 x 1024 x 1024 = 2097152
                  ],
                  color: 'indigo',
                  tooltip: ['上傳', '放棄上傳'],
                  isCollapse: false, /* 是否已展開 */
                  accept: 'pdf/*', // 逗號分隔，ex: "image/jpg, image/jpeg, application/pdf"
                  placeholder: '請選擇檔案',
                  hideDetails: 'auto'
                },
                edit: { id: 'edit', label: '編輯', icon: 'mdi-pencil', enabled: false, color: 'green', tooltip: '編輯' },
                delete: { id: 'delete', label: '刪除', icon: 'mdi-delete', enabled: false, color: 'red', tooltip: '刪除' }
              }
            }
          },
          record: {
            id: 'record',
            label: '處理紀錄',
            itemsPerPage: 5,
            loading: false,
            loadingText: '取得需求單處理紀錄中...',
            noDataText: '查無處理紀錄',
            headers: [
              { value: 'sno', text: '項次', align: 'left', sortable: false, width: '4rem', class: 'body-2 font-weight-black teal lighten-4' },
              { value: 'is_seq', text: '需求單號', align: 'left', sortable: false, class: 'body-2 font-weight-black teal lighten-4' },
              { value: 'type', text: '紀錄類型', align: 'left', sortable: false, class: 'body-2 font-weight-black teal lighten-4' },
              { value: 'logger', text: '操作人', align: 'left', sortable: false, class: 'body-2 font-weight-black teal lighten-4' },
              { value: 'ins_dt', text: '紀錄日期', align: 'left', sortable: false, class: 'body-2 font-weight-black teal lighten-4' }
            ],
            data: []
          }
        }
      }
    };
  },
  watch: {
    /**
     * [監聽Master data]
     */
    initVars: {
      handler (newVal, oldVal) {
        // console.log(`[ms][watch][wait] ... target: ${this.target}`);
        // console.log(newVal);
        // console.log(oldVal);
        // // loading
        // this.$set(this.mVars, 'loading', true);
        // this.$set(this.dVars.items[this.dVars.rid], 'loading', true);
        // // master
        this.initSet('master');
        // this.mVars.items = !newVal ? [] : newVal;
        // this.$set(this.mVars, 'loading', false);
        // // slave
        this.initSet('slave');
        // this.decodeDetail();
      },
      deep: true
    },
    /**
     * [監聽Slave 切換tab]
     */
    'dVars.tab': {
      handler (newTab, oldTab) {
        this.dVars.rid = Object.keys(this.dVars.items)[newTab];
        // console.log(`[ms][watch][dVars.tab] ... target: ${this.target}，t: ${this.dVars.rid}，tab: ${newTab} / ${oldTab}`);
        // 初始化於create執行，此處跳過
        if (oldTab === null) {
          // console.log('[ms][watch][dVars.tab] ... pass');
        }
        // 更新 Slave tab
        this.initSet('slave');
      }
    },
    /**
     * [監聽選擇上傳的估價單檔案]
     */
    'dVars.items.quot.speedDial.subs.upload.value': {
      handler (newFile) {
        if (!!newFile && newFile.size > 2_097_152) {
          this.$set(this.btns.quot, 'upload', false); // 停用
        } else {
          this.$set(this.btns.quot, 'upload', true);
        }
      },
      deep: true
    }
  },
  methods: {
    /**
       * [initSet]
       * @param string ms   [master / slave]
       */
    initSet (tg) {
      // console.log(`[ms][initSet] ... tg: ${tg}，target: ${this.target}`);
      // init format
      switch (tg) {
        case 'master':
          this.$set(this.mVars, 'loading', true);
          this.$set(this.mVars, 'title', this.title[this.target]);
          this.mVars.items = !this.initVars ? [] : this.initVars;
          this.$set(this.mVars, 'loading', false);
          break;
        case 'slave':
          this.$set(this.dVars.items[this.dVars.rid], 'loading', true);
          this.decodeDetail();
          break;
      }
    },
    /**
     * [runMsgDialog] control parent's MsgDialog
     */
    runMsgDialog (_dialog = null) {
      if (_dialog) {
        this.$emit('update:runMsgDialog', _dialog);
      }
    },
    /**
     * [queryDetail] 指定需求單(點擊列，處理顯示明細內容)
     * @param * rid mVars.items的列索引值
     */
    queryDetail (rid, evt) {
      // console.log(`[ms][queryDetail] ... target: ${this.target}，rid: ${rid}`);
      evt.preventDefault();
      // console.log(this.mVars.items[rid]);
      if (this.mVars.rid !== rid) {
        // console.log('[ms][queryDetail] ... refresh ms data');
        // click row
        this.$set(this.mVars, 'rid', rid);
        // 重新解析明細
        this.decodeDetail(); // 重新解析明細
      }
    },
    /**
     * [decodeDetail] 解析 Tab Detail
     */
    decodeDetail () {
      // console.log('[ms][decodeDetail] ... ');
      const rid = { m: this.mVars.rid, d: this.dVars.rid };
      const m = !this.mVars.items[rid.m] ? {} : this.mVars.items[rid.m];
      let d = null;
      // console.log(`tg: ${this.target}，rid: ${rid.m}，d_rid:${rid.d}`);
      switch (rid.d) {
        case 'inst': // 場勘資訊
          d = {};
          if (!m || Object.keys(m).length <= 0) {
            break;
          }
          d.is_seq = !m.is_seq ? '' : m.is_seq;
          d.assign_supplier = (!m.assign_type || m.assign_type !== 'A' || (m.assign_type === 'A' && !m.assign_remark)) ? '未指定' : m.assign_remark;
          d.supplier = !m.supplier_id ? '未派單' : `${m.supplier_id}／${m.supplier_user_id}`;
          d.parking_addr = !m.parking_addr_full ? '' : m.parking_addr_full;
          d.parking_env = !m.parking_env_name ? '' : m.parking_env_name;
          d.parking_type = (
            (!m.parking_type_name ? '' : m.parking_type_name) +
            ((!!m.parking_type && m.parking_type !== '_' && !!m.parking_type_remark) ? `／${m.parking_type_remark}` : '')
          );
          d.community = !!m.community_name && m.community_name !== '_' ? m.community_name : '';
          d.parking_model = !m.parking_model_name ? '' : m.parking_model_name;
          d.parking_loc = ((!m.parking_loc_name ? '' : m.parking_loc_name) + '／' +
            (!m.parking_floor ? '' : m.parking_floor) + '樓／' +
            (!m.parking_no ? '' : m.parking_no));
          d.contacter = ((!m.contact_name ? '' : m.contact_name) + '／' +
            (!m.contact_tel ? '' : m.contact_tel) + '／' +
            (!m.contact_time ? '' : m.contact_time));
          d.cmmunity_contact = ((!m.community_tel ? '' : m.community_tel) + '／' +
            (!m.community_meeting_dt ? '' : m.community_meeting_dt));
          d.opinions = !m.opinions ? '' : m.opinions;
          break;
        case 'quot': // 報價單
          d = [];
          if (!m || Object.keys(m).length <= 0 || !m.upload_files || !m.upload_files['01'] || m.upload_files['01'].length <= 0) {
            break;
          }
          m.upload_files['01'].forEach(function (u) {
            d.push({
              is_seq: m.is_seq,
              up_seq: u.up_seq,
              filename: u.filename,
              ext: u.ext,
              status: u.status,
              url: u.url,
              upload_dt: u.ins_dt,
              mod_dt: u.mod_dt
            });
          });
          // console.log(d);
          break;
        case 'record': // 處理紀錄
          d = [];
          if (!m || Object.keys(m).length <= 0) {
            break;
          }
          d.push({ is_seq: m.is_seq, type: '新增需求單', logger: 'XXX', ins_dt: '2021-03-03 10:23:45' });
          d.push({ is_seq: m.is_seq, type: '修改需求單', logger: 'XXX', ins_dt: '2021-03-03 15:16:23' });
          d.push({ is_seq: m.is_seq, type: '上傳報價單', logger: 'XXX', ins_dt: '2021-03-07 11:23:58' });
          d.push({ is_seq: m.is_seq, type: '上傳報價單', logger: 'XXX', ins_dt: '2021-03-08 15:38:15' });
          d.push({ is_seq: m.is_seq, type: '上傳報價單', logger: 'XXX', ins_dt: '2021-03-08 16:55:09' });
          d.push({ is_seq: m.is_seq, type: '111111', logger: 'XXX', ins_dt: '2021-03-09 10:23:45' });
          d.push({ is_seq: m.is_seq, type: '22222222222', logger: 'XXX', ins_dt: '2021-03-09 15:16:23' });
          d.push({ is_seq: m.is_seq, type: '333333333', logger: 'XXX', ins_dt: '2021-03-09 11:23:58' });
          d.push({ is_seq: m.is_seq, type: '444444444', logger: 'XXX', ins_dt: '2021-03-10 15:38:15' });
          d.push({ is_seq: m.is_seq, type: '55555555555', logger: 'XXX', ins_dt: '2021-03-10 16:55:09' });
          d.push({ is_seq: m.is_seq, type: '結案', logger: 'XXX', ins_dt: '2021-03-10 17:10:27' });
          break;
      }

      this.$set(this.dVars.items[rid.d], 'data', d);
      // disable loading
      this.$set(this.dVars.items[rid.d], 'loading', false);
    },
    /**
     * [clickBtns]
     * @param string caller   detail's sheet index or ['refresh']: 全部更新
     * @param string tg       speedDial's sub btn index
     */
    clickBtns (caller = '', tg = '') {
      // console.log(`[instlist][clickBtns] ... ${this.target} / ${caller} / ${tg}`);
      // console.log(this.dVars.items[caller]);
      if (caller === '') {
        return false;
      }

      let _collapse = false;
      switch (caller) {
        case 'refresh': // 全部更新
          // console.log('全部更新');
          this.$emit('update:runRefresh');
          break;
        default: // click speedDial's subs' btn
          // console.log('click speedDial sub btn');
          if (!!this.dVars.items[caller] && this.dVars.items[caller].speedDial && !!this.dVars.items[caller].speedDial.subs && !!this.dVars.items[caller].speedDial.subs[tg]) {
            _collapse = !!this.dVars.items[caller].speedDial.subs[tg].isCollapse;
            this.$set(this.dVars.items[caller].speedDial.subs[tg], 'isCollapse', !_collapse);
          }
          break;
      }
    },
    /**
     * [execFile] 檢視/下載/上傳 PDF檔案
     * @param string action  [view]: 檢視，['download']:下載，['downloadUrl']:下載 by Url
     * @param array param ['dVars.items的index', '欲檢視的資料列index']
     */
    async execFile (action = '', param = null) {
      let _dialog = this.$options.data().dialog;
      if (action === '' || !param || param.length < 2) {
        _dialog.persistent = false;
        _dialog.msg = '參數不正確';
        this.runMsgDialog(_dialog);
      }

      let _vars = null;
      let formData = null;
      let result = null;
      let fail = null;
      let row = null;
      let _speedDial = null;
      let _upload = null;
      let _isCollapse = null;
      let _tg = param[0];
      let _id = '';

      const _errCode = {
        101: '需求單 - 上傳檔案失敗',
        102: '上傳檔案 - 來源未定義',
        103: '請選擇上傳檔案',
        104: '非可上傳檔案狀態',
        105: '需求單 - 上傳檔案發生異常',
        106: '上傳檔案不可超過 2 MB'
      };

      // 點擊所在的明細資料列
      if (['upload', 'upload_drop'].indexOf(action) >= 0) { // 上傳
        _tg = this.dVars.rid;
        _id = 'upload';

        if (Object.keys(this.dVars.items).indexOf(_tg) < 0) {
          _dialog.msg = _errCode[102];
          _dialog.persistent = false;
          this.runMsgDialog(_dialog);
          return;
        }

        _speedDial = !this.dVars.items[_tg].speedDial ? null : this.dVars.items[_tg].speedDial;
        _upload = !this.dVars.items[_tg].speedDial.subs[_id] ? null : this.dVars.items[_tg].speedDial.subs[_id];
        _isCollapse = !this.dVars.items[_tg].speedDial.subs[_id].isCollapse;
      } else { // 檢視 / 下載
        row = this.dVars.items[param[0]].data[param[1]]; // 檢視的資料列內容
      }

      // by action
      _dialog = this.$options.data().dialog;
      switch (action) {
        case 'upload': // 上傳檔案
          // 檢核
          if (!_speedDial || !_upload || (_upload.enabled && !_upload.value)) {
            _dialog.msg = _errCode[103];
            _dialog.persistent = false;
            this.runMsgDialog(_dialog);
            return;
          }
          if (_isCollapse && action === '') {
            _dialog.msg = _errCode[104];
            _dialog.persistent = false;
            this.runMsgDialog(_dialog);
            return;
          }
          if (_upload.value.size > 2_097_152) { // 檔案大小不超過2MB
            _dialog.msg = _errCode[106];
            _dialog.persistent = false;
            this.runMsgDialog(_dialog);
            return;
          }

          // 上傳處理中
          formData = new FormData();
          formData.append('caller', process.env.VUE_APP_USER);
          formData.append('type', '01');
          formData.append('ref_id', this.mVars.items[this.mVars.rid].is_seq);
          formData.append('file', _upload.value);

          // dialog
          _dialog.title = `檔案: ${_upload.value.name} 上傳中 ...`;
          _dialog.persistent = true;
          _dialog.process = true;
          _dialog.showPercentage = true;
          _dialog.percentage = 0;
          _dialog.width = '400px';
          this.runMsgDialog(_dialog);

          try {
            // axios api
            result = await apiUploadFilePostStoreV2(formData, (event) => {
              _dialog.percentage = Math.round(100 * event.loaded / event.total);
            });
            // console.log(result);
            // 失敗
            if (!result || !result.status) {
              fail = { action: 'error', status: 101, msg: _errCode[101], data: result };
              console.log(fail);
              _dialog = this.$options.data().dialog;
              _dialog.init = true;
              _dialog.immediate = false;
              _dialog.msg = `${_errCode[101]}，error: [${result.data.status}] ${result.msg}`;
              _dialog.persistent = false;
              this.runMsgDialog(_dialog);
              return false;
            }
            // success
            // console.log('上傳成功');
            this.$set(this.dVars.items[_tg].speedDial.subs, _id, this.$options.data().dVars.items[_tg].speedDial.subs[_id]); // 關閉上傳列
            // 全部更新
            this.clickBtns('refresh');
            // 關閉 dialog
            _dialog.isClose = true;
            this.runMsgDialog(_dialog);
          } catch (error) {
            fail = { action: 'error', status: 105, msg: _errCode[105], data: error };
            console.log(fail);
            _dialog = this.$options.data().dialog;
            _dialog.init = true;
            _dialog.immediate = false;
            _dialog.msg = `${_errCode[105]}，error: [${result.data.status}] ${result.msg}`;
            _dialog.persistent = false;
            this.runMsgDialog(_dialog);
            return false;
          }
          break;
        case 'upload_drop': // 放棄上傳
          this.$set(this.dVars.items[_tg].speedDial.subs, _id, this.$options.data().dVars.items[_tg].speedDial.subs[_id]);
          break;
        case 'view': // 檢視檔案
          _vars = this.$options.data().pdfVars;
          _vars.active = true;
          _vars.mode = 'view';
          _vars.type = param[0];
          _vars.id = row.up_seq;
          _vars.filename = `${param[0]}_${row.filename}`; // 下載後另存檔名
          this.$set(this, 'pdfVars', _vars);
          break;
        case 'download': // 下載檔案
          _vars = this.$options.data().pdfVars;
          _vars.mode = 'download';
          _vars.type = param[0];
          _vars.id = row.up_seq;
          _vars.filename = `${param[0]}_${row.filename}`; // 下載後另存檔名
          this.$set(this, 'pdfVars', _vars);
          break;
        case 'downloadUrl': // 下載檔案 by Url (laravel 須建立 storage links)
          _vars = this.$options.data().pdfVars;
          _vars.mode = 'downloadUrl';
          _vars.url = '/quot/IS202102000002_01_20210325154116.pdf';
          _vars.filename = `${param[0]}_${row.filename}`; // 下載後另存檔名
          this.$set(this, 'pdfVars', _vars);
          break;
        default:
          _dialog.persistent = false;
          _dialog.msg = `檔案操作未定義: [${action}]`;
          this.runMsgDialog(_dialog);
          break;
      }
    }
  },
  created () {
    // console.log(`[ms][created] ...target: ${this.target}`);
    // console.log(this.initVars);
    // 初始化
    this.initSet('master');
    this.initSet('slave');
  },
  mounted () {
    // console.log(`[ms][mounted] ...target: ${this.target}`);
    // console.log(this.mVars);
    // console.log(this.dVars);
  },
  updated () {
    // console.log(`[ms][updated] ...target: ${this.target}`);
    // console.log(this.mVars);
    // console.log(this.dVars);
  }
};
</script>
<style scoped>
.upload-speed-dialog .v-speed-dial {
  position: absolute;
  z-index: 2; /* 與table header 同層 */
}

.upload-speed-dialog .v-btn--floating {
  position: relative;
}
</style>
