var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',{staticClass:"title font-weight-bold",staticStyle:{"background-color":"#c8e6c9"}},[_c('v-row',[_c('v-col',{attrs:{"cols":""}},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"40","color":"teal"}},[_c('v-icon',{attrs:{"middle":"","dark":""}},[_vm._v(_vm._s(_vm.topic.icon))])],1),_vm._v(" "+_vm._s(_vm.topic.label)+" ")],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"teal"},on:{"click":function($event){$event.stopPropagation();return _vm.switchPanel('search')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-tune")])],1)]}}])},[_c('span',[_vm._v("進階查詢")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({ref:"refreshBtn",attrs:{"icon":"","color":"teal"},on:{"click":function($event){$event.stopPropagation();return _vm.clickBtnActions('refresh')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("全部更新")])])],1)],1)],1),_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('v-expansion-panels',{attrs:{"accordion":"","flat":""},model:{value:(_vm.panel.active),callback:function ($$v) {_vm.$set(_vm.panel, "active", $$v)},expression:"panel.active"}},[_c('v-expansion-panel',[_c('v-expansion-panel-content',{staticClass:"myPanelCustom"},[_c('v-chip',{staticClass:"subtitle-1",attrs:{"color":"success","label":"","small":"","text-color":"white"}},[_vm._v(_vm._s("查詢條件"))]),_c('v-select',{staticClass:"mt-2",staticStyle:{"max-width":"30rem"},attrs:{"label":_vm.searchVars.topic.label,"items":_vm.searchVars.topic.options,"placeholder":_vm.searchVars.topic.placeholder,"multiple":"","outlined":"","dense":"","small-chips":"","hide-details":'auto'},model:{value:(_vm.searchVars.topic.value),callback:function ($$v) {_vm.$set(_vm.searchVars.topic, "value", $$v)},expression:"searchVars.topic.value"}}),_c('v-text-field',{staticClass:"mt-2",staticStyle:{"max-width":"30rem"},attrs:{"label":_vm.searchVars.seo.label,"placeholder":_vm.searchVars.seo.placeholder,"outlined":"","dense":"","hide-details":'auto'},model:{value:(_vm.searchVars.seo.value),callback:function ($$v) {_vm.$set(_vm.searchVars.seo, "value", $$v)},expression:"searchVars.seo.value"}}),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"success","outlined":"","small":"","disabled":_vm.searchVars.disabled},on:{"click":function($event){_vm.searchVars.disabled = true;
                    _vm.clickBtnActions('search', $event);}}},[_vm._v("送出")])],1)],1)],1),(_vm.panel.active !== null)?_c('v-divider'):_vm._e()],1),_c('v-card-text',{staticClass:"pa-0"},[_c('NormalTab',{attrs:{"initVars":_vm.tabVars}}),_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.tabVars.tab),callback:function ($$v) {_vm.$set(_vm.tabVars, "tab", $$v)},expression:"tabVars.tab"}},_vm._l((_vm.tabVars.items),function(d){return _c('v-tab-item',{key:("tabItem-" + (d.id))},[_c('MasterDetailTab',{key:("ms-" + (d.id)),attrs:{"target":d.id,"initVars":d.data},on:{"update:runRefresh":_vm.runRefresh,"update:runMsgDialog":_vm.runMsgDialog}})],1)}),1)],1)],1)],1)],1),_c('MsgDialog',{attrs:{"dialogProps":_vm.dialog},scopedSlots:_vm._u([{key:"custom-card-actions",fn:function(){return [(!!_vm.dialog.btns && !!_vm.dialog.btns.close)?_c('v-btn',{attrs:{"color":"green darken-1","outlined":""},on:{"click":function($event){_vm.dialog.isClose = true}}},[_vm._v("關閉")]):_vm._e()]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }