<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title
            style="background-color: #c8e6c9"
            class="title font-weight-bold"
          >
            <v-row>
              <v-col cols>
                <v-avatar size="40" color="teal" class="mr-2">
                  <v-icon middle dark>{{ topic.icon }}</v-icon>
                </v-avatar>
                {{ topic.label }}
              </v-col>
              <v-col cols="auto">
                <!-- <v-btn
                  color="teal"
                  outlined
                  class="subtitle-2 justify-end"
                  @click.stop="switchPanel('search')"
                  >{{ "進階查詢" }}</v-btn
                > -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="teal"
                      @click.stop="switchPanel('search')"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-tune</v-icon>
                    </v-btn>
                  </template>
                  <span>進階查詢</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      ref="refreshBtn"
                      icon
                      color="teal"
                      @click.stop="clickBtnActions('refresh')"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>全部更新</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="ma-0 pa-0">
            <v-expansion-panels v-model="panel.active" accordion flat>
              <v-expansion-panel>
                <v-expansion-panel-content class="myPanelCustom">
                  <v-chip
                    color="success"
                    label
                    small
                    text-color="white"
                    class="subtitle-1"
                    >{{ "查詢條件" }}</v-chip
                  >
                  <!-- <v-combobox
                    v-model="searchVars.topic.value"
                    :items="searchVars.topic.options"
                    :label="searchVars.topic.label"
                    :placeholder="searchVars.topic.placeholder"
                    multiple
                    outlined
                    dense
                    :hide-details="'auto'"
                    class="mt-2"
                  ></v-combobox> -->
                  <v-select
                    v-model="searchVars.topic.value"
                    :label="searchVars.topic.label"
                    :items="searchVars.topic.options"
                    :placeholder="searchVars.topic.placeholder"
                    multiple
                    outlined
                    dense
                    small-chips
                    :hide-details="'auto'"
                    class="mt-2"
                    style="max-width: 30rem"
                  ></v-select>
                  <v-text-field
                    v-model="searchVars.seo.value"
                    :label="searchVars.seo.label"
                    :placeholder="searchVars.seo.placeholder"
                    outlined
                    dense
                    :hide-details="'auto'"
                    class="mt-2"
                    style="max-width: 30rem"
                  ></v-text-field>
                  <v-btn
                    color="success"
                    outlined
                    small
                    class="mt-2"
                    :disabled="searchVars.disabled"
                    @click="
                      searchVars.disabled = true;
                      clickBtnActions('search', $event);
                    "
                    >送出</v-btn
                  >
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-divider v-if="panel.active !== null"></v-divider>
          </v-card-text>
          <!-- tabs / search / master / detail -->
          <v-card-text class="pa-0">
            <!-- 主題分類 tabs -->
            <NormalTab :initVars="tabVars"></NormalTab>
            <!-- Master / Slave -->
            <v-tabs-items v-model="tabVars.tab" touchless>
              <v-tab-item v-for="d in tabVars.items" :key="`tabItem-${d.id}`">
                <MasterDetailTab
                  :target="d.id"
                  :initVars="d.data"
                  :key="`ms-${d.id}`"
                  v-on:update:runRefresh="runRefresh"
                  v-on:update:runMsgDialog="runMsgDialog"
                ></MasterDetailTab>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- dialog -->
    <MsgDialog :dialogProps="dialog">
      <template v-slot:custom-card-actions>
        <v-btn
          color="green darken-1"
          outlined
          @click="dialog.isClose = true"
          v-if="!!dialog.btns && !!dialog.btns.close"
          >關閉</v-btn
        >
      </template>
    </MsgDialog>
    <!-- dialog for processing end -->
  </v-container>
</template>
<script>
/**
 * [需求單管理 > 列表]
 */
import { apiInstallationGetIndex } from '@api/installation.js';
import ArrayHandler from '@util/common/arrayHandler.js';
import LoginVerify from '@util/common/loginVerify.js';
import NormalTab from '@component/tab/normal';
import MasterDetailTab from '@component/ms/InstList';
import MsgDialog from '@component/dialog/MsgDialog';
export default {
  components: { NormalTab, MasterDetailTab, MsgDialog },
  data () {
    return {
      logger: {},
      panel: {
        active: null,
        items: ['search']
      },
      searchVars: { // 進階查詢
        disabled: false,
        topic: {
          tg: 'topic',
          id: 'topic',
          label: '主題分類',
          value: ['all'],
          options: [{ text: '所有單據', value: 'all' }],
          type: 'combobox',
          placeholder: '請選擇'
        },
        seo: {
          tg: 'seo',
          id: 'seo',
          label: '關鍵字',
          value: '',
          type: 'text',
          placeholder: '請輸入，多個關鍵字請以空格分隔'
        }
      },
      dialog: {
        init: false,
        active: false,
        isClose: false, // 關閉訊息窗
        title: '',
        msg: '',
        process: false,
        persistent: true, // 預設要點擊按鈕
        width: '350px',
        btns: {}
      },
      topic: {
        label: '',
        icon: ''
      },
      tabVars: {
        tab: null, // 預設第一個頁籤
        isSearch: false, // 是否查詢模式
        filters: null, // 進階查詢設定內容
        iconsAndText: true,
        height: '3.25rem',
        items: [ // icon: [no badge, has badge]
          { id: 'wait', label: '未接單', loading: false, api: { type: '11' }, icon: ['mdi-alarm-light-outline', 'mdi-alarm-light'], badge: 0, data: [] },
          { id: 'quot', label: '未報價', loading: false, api: { type: '12' }, icon: ['mdi-alarm-panel-outline', 'mdi-alarm-panel'], badge: 0, data: [] },
          { id: 'inst', label: '裝機中', loading: false, api: { type: '13' }, icon: ['mdi-certificate-outline', 'mdi-certificate'], badge: 0, data: [] },
          { id: 'closed', label: '未結案', loading: false, api: { type: '14' }, icon: ['mdi-package-variant-closed', 'mdi-package-variant'], badge: 0, data: [] },
          { id: 'finish', label: '已結案', loading: false, api: { type: '15' }, icon: ['mdi-checkbox-multiple-marked-outline', 'mdi-checkbox-multiple-marked'], badge: 0, data: [] }
        ]
      },
      msVars: {}
    };
  },
  watch: {
    /**
     * [監聽-切換主題分類tab]
     * 點擊時，master /salve 無資料 or 強制更新 才call api查詢
     */
    'tabVars.tab': {
      handler (newTabIdx, oldTabIdx) {
        // console.log(`[inst/list][watch][tabVars.tab] ... ${newTabIdx} / ${oldTabIdx}`);
        const t = this.tabVars.items[newTabIdx];
        // 初始化於create執行，此處跳過
        if (oldTabIdx === null || newTabIdx === null) {
          // console.log('[inst/list][watch][tabVars.tab] ... pass');
          return;
        }
        // 更新 Master / Slave (指定點擊tab目前MS無資料才查詢)
        if (!t.data || !t.data.length) {
          // 再次查詢
          let _param = {
            type: t.api.type,
            caller: process.env.VUE_APP_USER,
            member_uid: this.logger.mem_uuid,
            supplier_id: !this.logger.company ? '_' : this.logger.company
          };
          // 是否套用篩選條件
          if (this.tabVars.isSearch) {
            const _filters = this.tabVars.filters;
            if (_filters.topic.indexOf(this.tabVars.items[newTabIdx].id) < 0) {
              return;
            }
            _param = { ..._param, ..._filters };
            delete _param.topic;
            this.$set(this.searchVars, 'disabled', false);
            // console.log(_param);
          }
          this.getInstByFilter(_param, t.id, newTabIdx);
        } else {
          this.$set(this.tabVars.items[newTabIdx], 'data', t.data);
        }
      }
    }
  },
  methods: {
    /**
     * [initSet]
     * @param object filters 進階查詢條件
     */
    initSet (filters = null) {
      // 登入者
      this.logger = LoginVerify.getLogger();
      // topic
      this.$set(this.topic, 'label', !this.$route || !this.$route.meta.menuLabel ? '' : this.$route.meta.menuLabel);
      this.$set(this.topic, 'icon', !this.$route || !this.$route.matched[0].meta.icon ? '' : this.$route.matched[0].meta.icon);

      // 依主題分類查詢
      let _tab = '';
      let _vars = null;
      let _param = {};
      const _isSearch = (!!filters && Object.keys(filters).length > 0);
      // init tabVars for search
      if (_isSearch) {
        _vars = this.$options.data().tabVars;
        _vars.isSearch = true;
        _vars.filters = filters;
        this.$set(this, 'tabVars', _vars);
      }
      // by tab
      for (let i = 0; i < this.tabVars.items.length; i++) {
        _tab = this.tabVars.items[i].id;
        _param = {
          type: this.tabVars.items[i].api.type,
          caller: process.env.VUE_APP_USER,
          user_uid: this.logger.mem_uuid,
          supplier_id: !this.logger.company ? '_' : this.logger.company
        };

        // 是否進階查詢
        if (_isSearch) {
          // console.log(filters);
          // 符合主題才查
          if (filters.topic.indexOf(_tab) >= 0) {
            _param = { ..._param, ...filters };
            delete _param.topic;
            if (this.tabVars.tab === null) { // 查詢後預設開啟頁籤
              // console.log(`search with default: ${i} ...`);
              this.$set(this.tabVars, 'tab', i);
            }
          } else {
            console.log(`skip ... [${_tab}]`);
            continue;
          }
        } else {
          // 初始化 - 進階搜尋 - 主題分類選項
          this.searchVars.topic.options.push({ text: this.tabVars.items[i].label, value: this.tabVars.items[i].id });
        }
        // loading
        this.tabVars.items[i].loading = true;
        // call api
        console.log(_param);
        this.getInstByFilter(_param, this.tabVars.items[i].id, i);
      }
    },
    /**
     * [getInstByFilter] 查詢指定條件的需求單資料
     * @param (*) param
     * @param string tg
     * @param int    rid 指定tab的items索引值
     */
    async getInstByFilter (params = null, tg = '', rid = -1) {
      let _dialog = this.$options.data().dialog;
      if (!params) {
        _dialog.persistent = false;
        _dialog.msg = '入參不正確';
        this.$set(this, 'dialog', _dialog);
        return false;
      }

      const _errCode = {
        101: '取得需求單失敗',
        102: '查無資料',
        105: '取得需求單發生異常'
      };

      // // dialog with progress
      // _dialog.process = true;
      // _dialog.persistent = true;
      // _dialog.title = '取得需求單處理中，請稍後...';
      // this.$set(this, 'dialog', _dialog);

      let fail = {};
      let result = null;
      // dialog
      _dialog = this.$options.data().dialog;
      // console.log('call api: apiInstallationGetIndex ...');
      try {
        // axios api
        result = await apiInstallationGetIndex(params);
        // console.log(result);
        this.searchVars.disabled = false;

        // disabled loading
        this.tabVars.items[rid].loading = false;
        // 失敗
        if (!result || !result.status) {
          fail = { action: 'error', status: 101, msg: _errCode[101], data: result };
          _dialog.title = _errCode[101];
          _dialog.msg = result.msg;
          _dialog.persistent = false;
          this.$set(this, 'dialog', _dialog);
          return false;
        }
        if (!result.data) {
          fail = { action: 'error', status: 102, msg: _errCode[102], data: result };
          _dialog.title = _errCode[102];
          _dialog.msg = result.msg;
          _dialog.persistent = false;
          this.$set(this, 'dialog', _dialog);
          return false;
        }
        // success
        // console.log(`取得需求單成功[${tg}]`);
        // console.log(result.data);
        // 更新badge數字
        this.$set(this.tabVars.items[rid], 'badge', (result.data.length ? result.data.length : 0));
        // 更新tabs' item data
        this.$set(this.tabVars.items[rid], 'data', result.data);
      } catch (error) {
        fail = { action: 'error', status: 105, msg: _errCode[105], data: error };
        console.log(_errCode[105]);
        console.log(fail);
        _dialog.msg = error;
        _dialog.persistent = false;
        this.$set(this, 'dialog', _dialog);
        return false;
      }
    },
    /**
     * [switchPanel] 切換開合式選單
     * @param string tg 點擊選單列
     */
    switchPanel (tg) {
      let _active = null;
      switch (tg) {
        case 'search': // 進階查詢
          _active = this.panel.items.indexOf(tg);
          break;
      }
      this.panel.active = (_active === null || _active < 0 || this.panel.active === _active) ? null : _active;
    },
    /**
     * [runRefresh] refresh by component
     */
    runRefresh (param) {
      // console.log('runRefresh ...');
      // console.log(this.$refs);
      this.$refs.refreshBtn.$el.click();
    },
    /**
     * [runMsgDialog] let component can run MsgDialog
     */
    runMsgDialog (param) {
      this.$set(this, 'dialog', param);
    },
    /**
     * [clickBtnActions]
     * @param string tg  點擊按鈕，['search']: 送出-進階查詢
     *                            ['refresh]: 全部更新
     */
    clickBtnActions (tg = '', evt) {
      // console.log(`[inst/list][clickBtnActions] ... tg: ${tg}`);
      let _topics = []; // 查詢條件 (預設[all]:所有主題)
      let _search = null;
      let _tabVars = null;
      const _filters = {};
      const _topicList = ArrayHandler.array_column(this.tabVars.items, 'id');
      const _dialog = this.$options.data().dialog;
      switch (tg) {
        case 'refresh': // 全部更新

          this.$set(this, 'panel', this.$options.data().panel); // reset panel
          this.$set(this, 'searchVars', this.$options.data().searchVars); // reset search
          // reset tabVars and defaut is current tab
          _tabVars = this.$options.data().tabVars;
          _tabVars.tab = this.tabVars.tab;
          this.$set(this, 'tabVars', _tabVars);
          // refresh
          this.initSet();
          break;
        case 'search': // 進階查詢
          _search = this.searchVars;
          Object.keys(_search).forEach(function (k) {
            switch (k) {
              case 'disabled': // 跳過
                break;
              case 'topic': // 主題分類
                if (_search.topic.value != null && _search.topic.value.length > 0) {
                  if (_search.topic.value.indexOf('all') >= 0) { // 所有主題
                    _topics = _topicList;
                  } else { // 指定主題
                    _topics = _search.topic.value;
                  }
                }
                break;
              default: // 其他條件(有賦值才使用)
                if (
                  (typeof _search[k].value === 'string' && _search[k].value !== '') ||
                  (Array.isArray(_search[k].value) && _search[k].value.length > 0)
                ) {
                  _filters[k] = _search[k].value;
                }
                break;
            }
          });

          // 未設定條件
          if (_topics.length === 0 && Object.keys(_filters).length === 0) {
            _dialog.persistent = false;
            _dialog.msg = '請設定篩選條件';
            this.$set(this, 'dialog', _dialog);
            this.$set(this.searchVars, 'disabled', false);
            return;
          }
          // 未指定主題，則全查
          _filters.topic = _topics.length === 0 ? _topicList : _topics;
          // console.log(_filters);
          // 重新查詢
          this.initSet(_filters);
          break;
        default:
          console.log('click btn with undefined target');
          break;
      };
    }
  },
  created () {
    // console.log('[Inst/list][created] ...');
    // console.log(this.$store.state);
    this.initSet();
  },
  updated () {
    // console.log('[Inst/list][updated] ...');
    // console.log(this.tabVars);
  }
};
</script>
