<template>
  <v-tabs
    v-model="tabVars.tab"
    :icons-and-text="tabVars.iconsAndText"
    show-arrows
    background-color="transparent"
    color="teal"
    slider-size="5"
    :height="tabVars.height"
    grow
  >
    <v-tab
      class="pt-5 px-5 px-sm-6 myTabCustom"
      v-for="(t, tid) in tabVars.items"
      :key="`tab-${tid}`"
    >
      <div
        class="d-flex flex-row subtitle-2 font-weight-bold grey--text text--darken-2 align-self-center"
      >
        <v-progress-circular
          indeterminate
          color="teal"
          size="20"
          class="mr-1"
          v-if="t.loading"
        ></v-progress-circular>
        <template v-if="!!t.badge && t.badge > 0">
          <v-badge color="red" :content="t.badge" class="d-flex flex-row"
            ><v-icon class="d-none d-sm-flex mr-1" v-text="t.icon[1]"></v-icon>
            <div class="align-self-center">{{ t.label }}</div></v-badge
          >
        </template>
        <template v-else>
          <v-icon class="mr-1" v-text="t.icon[0]"></v-icon>{{ t.label }}
        </template>
      </div>
    </v-tab>
  </v-tabs>
</template>
<script>
/**
 * [tab/normal] tab客製化 > 一般通用
 */
export default {
  name: 'NormalTab',
  props: {
    initVars: {
      type: Object,
      default: function () {
        return {
        };
      }
    }
  },
  data () {
    return {
      tabVars: this.initVars
    };
  },
  watch: {
    initVars: {
      handler (newVal, oldVal) {
        // console.log('[tab/normal][watch][initVars] ...');
        // console.log(newVal);
        // console.log(oldVal);
        // console.log(typeof oldVal);
        if (typeof oldVal === 'undefined') {
          // console.log('[tab/normal][watch][initVars] ... pass');
          return;
        }
        this.tabVars = newVal;
      },
      deep: true
    }
  },
  methods: {
    /**
     * [initSet]
     */
    initSet () {
      // 預設值
      const _initList = {
        tab: null,
        iconsAndText: false,
        height: false,
        items: {
          id: '',
          label: '',
          icon: '',
          badge: 0
        }
      };
      const _keys = Object.keys(_initList);
      let i = 0;
      let _key = '';
      for (i = 0; i < _keys.length; i++) {
        _key = _keys[i];
        if (!this.tabVars || this.tabVars[_key] == null) {
          this.tabVars[_key] = _initList[_key];
        }
      }
    }
  }
};
</script>
<style scoped>
.myTabCustom {
  background-color: #c8e6c9;
  border-right: 1px solid white;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
}
.myTabCustom:last-child {
  border-right: 0;
}
/* .myTabCustom .v-tab {
  padding-left: 0 !important;
  padding-right: 0 !important;
} */
</style>
