/**
 * Installation 相關 api
 */
import req from './config/https.js';

/**
 * 定義接口
 * 1. apiInstallationGetIndex 需求單-CRUD-index
 * 2. apiInstallationPostStore 需求單-CRUD-store
 * 3. apiInstallationPostUploadFiles 上傳需求單關聯明細檔案
 *
 */
// 1.
export const apiInstallationGetIndex = function (params) {
  return req('get', '/api/inst', params);
};
// 2.
export const apiInstallationPostStore = function (params) {
  return req('post', '/api/inst', params);
};
// 2.
export const apiInstallationPostUploadFiles = function (params) {
  return req('post', '/api/inst/upload', params);
};
// 2.
// export const apiMemberPostRegisterVerify = (params) =>
//   req('post', '/api/member/register/verify', params);
// // 1.
// export const apiOAuthPostTokens = (params) =>
//   req('post', '/api/oauth/oauth_token', params);
// // 2.
// export const apiOAuthPostRefreshTokens = (params) =>
//   req('post', '/api/oauth/refresh_token', params);
